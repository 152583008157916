import { Chat } from '@/views/telegram/types'
import { RcFile } from 'antd/es/upload'

interface Columns {
  width?: string | number
  children?: Columns[]
}

/**
 * @description 生成指定位数随机数字
 * @param {Number} count 生成位数
 * @return {String}
 */
export function getRandom (count: number = 6): string {
  return String(Math.random()).slice(-count)
}

/**
 * @description 判断参数是否为对象
 *
 * @param  {*} target
 * @return {boolean}
 */
export function isObject (target: any): boolean {
  return Object.prototype.toString.call(target) === '[object Object]'
}

/**
 * @description 通过key查找菜单
 * @param menus 树形菜单数据
 * @param key 对比的数据key
 * @param value 对比的数据value
 * @param childrenKey 子节点字段
 * @return menu 菜单
 */
export function findMenuByKey<T, K extends keyof T> (menus: T[] = [], key: K, value: T[K], childrenKey: string = 'children'): T | void {
  for (let i = 0, item; (item = menus[i]); i++) {
    if (item[key] === value) {
      return item
    } else {
      item = findMenuByKey(item[childrenKey as K] as unknown as T[], key, value, childrenKey)
    }
    if (item) return item as T
  }
}

/**
 * @description 获取当前设备平台
 */
export function getPlatform () {
  const u = navigator.userAgent
  const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  const isAndroid = !!~u.indexOf('Android') || !!~u.indexOf('Adr')
  const isMobile = !!~u.indexOf('Mobile')
  if (isIOS) {
    return 'ios'
  } else if (isAndroid) {
    return 'android'
  } else if (isMobile) {
    return 'mobile'
  } else {
    return 'pc'
  }
}

/**
 * @description 倒叙字符串
 * @param {string} str
 * @return {String}
 */
export function reverseString (str: string) {
  return String(str).split('').sort().reverse().join('')
}

/**
 * @description 复制文本到粘贴板
 * @param {String} text
 * */
export function copyText (text: string) {
  const input = document.createElement('textarea')
  input.value = text
  document.body.appendChild(input)
  input.select() // 选择对象
  document.execCommand('Copy') // 执行浏览器复制命令
  input.remove()
}

/**
 * @description 保留指定位小数（向下舍去）
 * @param {number|string} number 转换数字
 * @param {number} toFixed 保留几位
 * @return {string}
 * */
export function floatToFixed (number: number = 0, toFixed = 2) {
  const time = Math.pow(10, toFixed)
  return (Math.floor(number * time) / time).toFixed(toFixed)
}

/**
 * @description 将数字转换为千位分割
 * @param {string} number 转换数字
 * @param {number} toFixed 保留几位
 * @return {string}
 * */
export function toThousandSplit (number: number = 0, toFixed?: number) {
  let numberStr = String(number)
  if (typeof toFixed === 'number') {
    numberStr = floatToFixed(number, toFixed)
  }
  return numberStr.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
}

/**
 * @description 获取表格列总宽度
 * @param {Array} columns 表格列
 * @param {Number} initialValue 初始化值，比如要额外添加或减少的宽度
 * @return {Number}
 * */
export function getTableScrollX (columns: Columns[], initialValue?: number) {
  return columns.reduce((total, item) => {
    total += Number(item.width) || 0
    if (item.children) total += getTableScrollX(item.children)
    return total
  }, initialValue || 0)
}

/**
 * @description 获取ant-table滚动高度
 * @param {number} pagination 默认56 分页容器高度
 * @param {boolean} mobileAlso 移动端单列的情况，又需要表格翻页滚动到第一行的情况
 * @return {number}
 * */
export function getTableScrollY (pagination = 56, mobileAlso = false) {
  const isPc = getPlatform() === 'pc'
  const padding = isPc ? 20 : 0 // 移动端没有边距
  const contentHeight = document.querySelector('#layout_content')?.clientHeight || 0
  let toolbarHeight = document.querySelector('.common__header')?.clientHeight || 0
  let tHeadHeight = document.querySelector('.ant-table-thead')?.clientHeight || 0
  const tFooterHeight = document.querySelector('.ant-table-footer')?.clientHeight || 0
  const tSummaryHeight = document.querySelector('.ant-table-summary')?.clientHeight || 0
  // 工具条有时候会多8px
  toolbarHeight = toolbarHeight === 64 ? 56 : toolbarHeight
  // 多行表格头会多1px边框
  tHeadHeight += tHeadHeight > 47 ? 1 : 1
  // console.log('contentHeight', contentHeight, padding, pagination)
  // console.log('toolbarHeight', toolbarHeight)
  // console.log('tHeadHeight', tHeadHeight)
  // console.log('tFooterHeight', tFooterHeight)
  // console.log('tSummaryHeight', tSummaryHeight)

  if (isPc || mobileAlso) {
    return contentHeight - padding - pagination - toolbarHeight - tHeadHeight - tFooterHeight - tSummaryHeight
  } else {
    const table = document.querySelector('.ant-table') as HTMLDivElement
    const maxHeight = contentHeight - toolbarHeight - pagination
    table && (table.style.cssText = `overflow-y:auto;max-height:${maxHeight}px;`)
  }
}

/**
 * @description 格式化表格filter数据
 *
 * @param {Object} filter 表格筛选原始数据
 * @return {Object}
 * */
export function formatFilterValue (filter: Record<string, any>) {
  const obj: Record<string, any> = {}
  for (const key in filter) {
    if (filter[key] !== null && filter[key] !== undefined) {
      obj[key] = filter[key].length === 1 ? filter[key][0] : filter[key]
    }
  }
  return obj
}

/**
 * @description 重置表格filter数据
 *
 * @param {Object} filter 表格筛选原始数据
 * @return {Object}
 * */
export function resetFilterValue (filter: Record<string, any>) {
  const obj: Record<string, any> = {}
  for (const key in filter) {
    obj[key] = undefined
  }
  return obj
}

/**
 * @description iphone上video不会显示封面，因此获取视频第一帧
 *
 * @param {String} url 表格筛选原始数据
 * @return {Promise}
 * */
export function getVideoCover (url: string): Promise<string> {
  return new Promise((resolve) => {
    const video = document.createElement('video')
    video.style.cssText = 'position: fixed;z-index: -1;'
    video.src = url
    video.muted = true
    video.autoplay = true
    video.currentTime = 0
    video.crossOrigin = 'anonymous'
    video.playsInline = true
    video.onloadeddata = () => {
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      ctx!.drawImage(video, 0, 0, canvas.width, canvas.height)
      resolve(canvas.toDataURL('image/jpeg'))
      video.parentNode?.removeChild(video)
    }
  })
}

/**
 * @description 遮挡用户名
 *
 * @param {String} text 表格筛选原始数据
 * @return {String}
 * */
export function coverText (text?: string) {
  if (!text) return text
  return text.slice(0, 2) + '***' + text.slice(-2)
}

/**
 * @description 节流
 * @param {Function} fn 执行方法
 * @param {Number} delay 间隔时间
 * @return {Function}
 * */
export function throttle<T = unknown> (fn: (...rest: T[]) => void, delay: number) {
  let timer: NodeJS.Timeout | null = null
  return function (...rest: T[]) {
    if (timer === null) {
      fn(...rest)
      timer = setTimeout(() => {
        timer = null
      }, delay)
    }
  }
}

/**
 * @description 判断当前TG账号在群组中是够有发言权限
 * @param {Object} chat
 * @return boolean
 * */
export function canSendMessage (chat: Chat): boolean {
  let bool
  if (chat._ === 'channelForbidden') {
    bool = false
  } else if (chat.broadcast) {
    bool = chat.admin_rights?.post_messages
  } else {
    bool = chat.admin_rights || (!chat.default_banned_rights?.send_messages && !chat.banned_rights?.send_messages)
  }
  return !!bool
}

/**
 * @description 判断当前TG账号在群组中是否有拉人权限
 * @param {Object} chat
 * @return boolean
 * */
export function canInviteUsers (chat: Chat): boolean {
  let bool
  if (chat._ === 'chat' || chat._ === 'channelForbidden') {
    bool = false
  } else if (chat.broadcast) {
    bool = chat.admin_rights?.invite_users
  } else {
    bool = chat.admin_rights?.invite_users || (!chat.default_banned_rights?.invite_users && !chat.banned_rights?.invite_users)
  }
  return !!bool
}

/**
 * @description 将数据转换为FormData
 *
 * @param {Object} data
 * @return {FormData}
 * */
export function transformRequestDataToFormData (data: Record<string, any>) {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    const value = data[key]
    if (value instanceof File) {
      formData.set(key, value)
    } else if (Array.isArray(value) && value[0] instanceof File) {
      value.forEach(item => formData.append(key, item))
    } else {
      value && formData.set(key, JSON.stringify(value))
    }
  })
  return formData
}

/**
 * @description 检查文件是否符合要求
 *
 * @param {RcFile} file
 * @return {Promise}
 * */
export function validaImageFile (file: RcFile) {
  const allowFileType = ['image/jpeg', 'image/png', 'image/gif']
  const isLt512KB = file.size < 1024 * 512
  const isJpgOrPng = allowFileType.includes(file.type)

  if (!isJpgOrPng) {
    return Promise.reject(new Error('请上传JPG/PNG图片'))
  } else if (!isLt512KB) {
    return Promise.reject(new Error('图片须小于512KB'))
  }
  return Promise.resolve()
}

/**
 * @description 将buffer转为base64
 *
 * @param {Array} buffer
 * @return {string}
 * */
export function transformArrayBufferToBase64 (buffer: number[] | Uint8Array) {
  let binary = ''
  let bytes: Uint8Array | null = new Uint8Array(buffer)
  for (let len = bytes.byteLength, i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  bytes = null
  return window.btoa(binary)
}

/**
 * @description 将buffer转为base64图片
 *
 * @param {Array} buffer
 * @return {string}
 * */
export function transformArrayBufferToBase64Image (buffer: number[] | Uint8Array) {
  return 'data:image/jpeg;base64,' + transformArrayBufferToBase64(buffer)
}

/**
 * @description 将file转为buffer图片
 *
 * @param {file} file
 * @return {buffer}
 * */
export function fileToBuffer (file: RcFile) {
  let fr: any = new FileReader()
  fr.readAsArrayBuffer(file)

  return new Promise((resolve) => {
    fr.onloadend = (e: any) => {
      resolve(e.target!.result)
      fr = null
    }
  })
}

/**
 * @description 导出数据
 * @param {string} data
 * @param {string} name
 * */
export function exportRaw (data: string, name: string) {
  const url = window.URL || window.webkitURL || window
  const blob = new Blob([data])
  const link: any = document.createElement('a')
  link.href = url.createObjectURL(blob)
  link.download = name
  link.click()
}

/**
 * @description 加载js文件并返回挂载在window上的变量
 * @param {string} src js文件路径
 * @param {string} id script标记，防止重复加载
 * */
export function loadScript (src: string, id?: string) {
  id = id || src
  if (document.getElementById(id)) return Promise.resolve(null)

  const script = document.createElement('script')
  script.id = id
  script.src = src
  script.defer = true
  script.async = true
  document.body.appendChild(script)

  return new Promise((resolve, reject) => {
    script.onload = function () {
      script.onload = null
      // script.parentNode!.removeChild(script)
      resolve(null)
    }
    script.onerror = reject
  })
}

/**
 * @description 获取指定位数的数字字母组合
 * @param {number} length 指定长度
 * */
export function randomString (length: number) {
  const str = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = length; i > 0; --i) {
    result += str[Math.floor(Math.random() * str.length)]
  }
  return result
}
