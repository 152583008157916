import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { LogoutOutlined, UnlockOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons'
import { Avatar, Dropdown, MenuProps } from 'antd'
import { getUserSelector, clearUser } from '@/store/slice/user'
import { useDispatch, useSelector } from 'react-redux'
import { /* coverText, */ transformArrayBufferToBase64Image } from '@/utils'
import { useNavigate } from 'react-router'
import { getActiveAccount } from '@/store/slice/account'
import UpdateEmail from './UpdateEmail'
import UpdatePassword from './UpdatePassword'
import scss from '../index.module.scss'
import * as tg from '@/views/telegram/api'

interface Props {
  className?: string
  setOpenSettingDrawer: Dispatch<SetStateAction<boolean>>
}

const UserDropdown: React.FC<Props> = (props) => {
  const { className, setOpenSettingDrawer } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [open, setOpen] = useState<Record<string, boolean>>({})
  const [photo, setPhoto] = useState<string>()

  const account = useSelector(getActiveAccount)
  const { username } = useSelector(getUserSelector)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(getProfilePhoto, [account?.user.photo?.photo_id])

  const items: MenuProps['items'] = [
    // {
    //   key: 'myCenter',
    //   icon: <UserOutlined/>,
    //   label: '个人中心'
    // },
    // {
    //   type: 'divider'
    // },
    {
      key: 'password',
      icon: <UnlockOutlined/>,
      label: '修改密码'
    },
    {
      key: 'email',
      icon: <MailOutlined/>,
      label: '修改邮箱'
    },
    {
      type: 'divider'
    },
    {
      key: 'pageSetting',
      icon: <SettingOutlined/>,
      label: '页面样式'
    },
    {
      type: 'divider'
    },
    {
      key: 'logout',
      icon: <LogoutOutlined/>,
      label: '退出登录'
    }
  ]

  // 获取头像
  function getProfilePhoto () {
    setPhoto(undefined)
    if (!account?.user.photo?.photo_id) return
    const params = {
      _id: account._id,
      id: account.user.id,
      big: false,
      access_hash: account.user.access_hash,
      photo_id: account.user.photo.photo_id
    }
    // tg.getProfilePhoto(params).then(data => {
    //   setPhoto(transformArrayBufferToBase64Image(data))
    // })
  }

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'myCenter':
        return navigate('/myCenter')
      case 'email':
        return setOpen({ email: true })
      case 'password':
        return setOpen({ password: true })
      case 'logout':
        return dispatch(clearUser())
      case 'pageSetting':
        return setOpenSettingDrawer(true)
    }
  }

  return (
    <>
      <Dropdown menu={{ items, onClick }} placement="bottomRight">
        <div className={className}>
          {/* { */}
          {/*   account */}
          {/*     ? <Avatar src={photo} style={{ backgroundColor: '#7265e6' }}> */}
          {/*       {account.user.first_name.slice(0, 1).toUpperCase()} */}
          {/*     </Avatar> */}
          {/*     : <Avatar style={{ backgroundColor: '#7265e6' }}> */}
          {/*       {username.slice(0, 1).toUpperCase()} */}
          {/*     </Avatar> */}
          {/* } */}
          <Avatar style={{ backgroundColor: '#7265e6' }}>
            {username.slice(0, 1).toUpperCase()}
          </Avatar>
          <span className={scss.header__username}>{username}</span>
        </div>
      </Dropdown>
      <UpdateEmail open={open.email} onCancel={() => setOpen({})}/>
      <UpdatePassword open={open.password} onCancel={() => setOpen({})}/>
    </>
  )
}

export default UserDropdown
